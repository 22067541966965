// Module - 11 - Plans // Slider

.module-11-plans-slider{

    .title_top {
        .btn {
            margin-top: 40px;
        }
    }

    .slider_wrap {
       display: flex;
       flex-wrap: wrap;
       justify-content: space-between;
       @include break(mobile){
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow-y: hidden;
        overflow-x: scroll;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
       }
    }

    .card-plan {
        width: 408px;
        height: 408px;
        margin-bottom: 30px;
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        color: $white;
        transition: none;
        @include break(mobile){
            width: 306px;
            height: 306px;
            flex-shrink: 0;
            margin-right:16px;
            scroll-snap-align: start;
            scroll-margin:16px;
        }

        .card-filter {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.5;
            background: linear-gradient(180deg, rgba(52, 52, 54, 0.00) 0%, #343436 100%);
            z-index: 1;
        }
        &_top {
            background-position: center;
            background-size: cover;
            width: 100%;
            position: relative;
            height: 100%;
        }

        .arrow {
           position: absolute;
           top: 32px;
           right: 32px;
           width: 45px;
           height: 45px;
           display: flex;
           align-items: center;
           justify-content: center;
           z-index: 4;
           transition: all 0.4s;
           background-color: rgba($secondary, 0);
           border-radius: 50%;
           visibility: visible;
           opacity: 1;
           svg {
            width: 27px;
            height: 27px;
            path {
                transition: fill 0.4s;
            }
           }

           @include break(mobile){
                top: 22px;
                right: 22px;
                width: 52px;
                height: 52px;
                svg {
                    width: 28px;
                    height: 28px;
                }
            }
        }

        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            padding: 40px 34px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            transition: background-color 0.4s;
            overflow-y: scroll;
            &::-webkit-scrollbar { width: 0 !important };
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;
            overflow-x: hidden;
            z-index: 2;
            @include break(mobile){
                padding: 32px 26px;
            }

            .title-h3 {
                margin-bottom: 0;
                color: $white;
                position: relative;
                z-index: 2;
            }
        }

        .card-plan_overlay-bg {
            position: absolute;
            width: 45px;
            height: 45px;
            background-color: $blue;
            border-radius: 50%;
            top: 32px;
            right: 32px;
            transition: all 0.6s;
            z-index: 1;
            @include break(mobile){
                top: 22px;
                right: 22px;
                width: 52px;
                height: 52px;
            }
        }

        &_txt {
           display: none;
           overflow: hidden;
           z-index: 2;
           position: relative;
           @include break(mobile){
                display: block!important;
                height: auto!important;
           }
        }
        &_txt-inner {
            padding-top: 10px;
        }

        &.right-slide {
          .card-plan_overlay-bg {
          }

          .arrow {

          }

          &:hover {
              .arrow {

              }

              .card-plan_overlay-bg {
                  transform: scale(0);
              }
          }

        }

        &:hover {
            .arrow {
                background-color: rgba($secondary, 1);
                transform: scale(1.1);
                svg path {
                    fill: $blue;
                }
            }

            .card-plan_overlay-bg {
                transform: scale(30);
            }
        }

        &.ghost {
            padding: 0!important;
            height: 0!important;
            @include break(mobile){
                display: none;
                width: 0;
            }
        }
    }

}
