@keyframes rotate {
  0%{
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100%{
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

.anime-txt, .anime-img, .anime-stagger div, .anime-stagger a, .anime-txt_first, .anime-logo {
  opacity: 0;
  transform-origin: left center;
}

.anime-titre {
  transform-origin: bottom left;
  overflow: hidden;
  line-height: 1.2em;
  padding-bottom: 0.16em;

  .inner {
    display: block;
    transform: translateY(115%);
  }
}


.anime-key {
  opacity: 0;
}
