html{
  overflow-x: hidden;
  overflow-y: scroll;
  &.overflow{
    overflow: hidden;
  }
  background-color: $dark;
  color: $dark;
  &::-webkit-scrollbar { width: 0 !important };
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  min-height: -webkit-fill-available;
}

body {
  &.overflow{
    overflow: hidden;
  }
  background-color: $white;
  color: $dark;
  font-size: $font-size;
  line-height: 140%;
  @include break(mobile){
    font-size: 18px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  @include font-regular;
  font-style: normal;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  opacity: 0;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 9999;
  z-index: 9999999999;
  background: linear-gradient(250deg, $blue 0%, $blue 5%, $cyan 45%, $cyan 75%, $blue 95%);
  background-size: 2000%;
  background-position: -10%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: bg_loader 10s infinite linear;
}
@keyframes bg_loader {
  0%{
    background-position: -10%;
  }
  100%{
    background-position: 95%;
  }
}

@include break(tablet) {
  .hide-mobile {
    display: none;
  }
}

.section {
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
}

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: $container;
}

.small-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1064px;
}

.flex {
  display: flex;
  justify-content: space-between;
  >* {
    width: 50%;
  }
}

.mobile {
  display: none;
  @include break(mobile) {
    display: block;
  }
}
.desktop{
  @include break(mobile) {
    display: none;
  }
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;

  // &:hover {
  //   color: $primary;
  // }
}

h1, h2, h3, h4 {
  a {
    color: inherit;
    text-decoration: none;
  }
}

p a {
  color: $primary;
}

.ghost {
  height: 0!important;
  padding: 0!important;
  margin: 0!important;
  border: none!important;
}


h1, .title-h1 {
  @include font-title;
  font-size: 4em;
  line-height: 123%;
  margin: 0;
  @include break(tablet){
    font-size: 2.5em;
  }
}
h2, .title-h2 {
  @include font-title;
  font-size: 3em;
  line-height: 118%;
  margin: 0;
  @include break(tablet){
    font-size: 2.2em;
  }
}
h3, .title-h3 {
  @include font-title;
  font-size: 2.4em;
  line-height: 120%;
  margin: 0;
  @include break(tablet){
    font-size: 1.77em;
  }
}
h4, .title-h4 {
  @include font-title;
  font-size: 1.8em;
  line-height: 120%;
  margin: 0;
  @include break(tablet){
    font-size: 1.44em;
  }
}
h5, .title-h5 {
  @include font-title;
  font-size: 1.4em;
  line-height: 120%;
  margin: 0;
  @include break(tablet){
    font-size: 1.22em;
  }
}
h6, .title-h6 {
  @include font-bold;
  font-size: 1em;
  line-height: 120%;
  margin: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}


// Background
.bg_white {
  background-color: $white;
}
.bg_dark {
  background-color: $dark;
}

// Boutons / Link
a.btn, div.btn {
  display: inline-flex;
  align-items: center;
  padding: 16px 32px;
  border-radius: 42px;
  background: $gradient;
  background-size: 250% 100%;
  background-position: 0% 0%;
  color: $white;
  line-height: 100%;
  font-size: 18px;
  transition: all 0.8s;
  cursor: pointer;
  @include font-medium;
  svg {
    margin-left: 12px;
  }

  &:hover {
    background-position: 65% 0%;
  }

  &.btn_white {
    background: linear-gradient(250deg, #ffffff 30%, rgba(#00E6E6, 1) 62%);
    background-color: #FFF;
    background-size: 400% 100%;
    background-position: -167% 0%;
    color: $blue;
    svg path {
      fill: $blue;
    }

    &:hover {
      background-position: -265% 0%;
    }
  }

  &.btn_border {
    border: 1px solid #FFF;
    background: linear-gradient(250deg, rgba(255,255,255,0) 30%, rgba(255,255,255,1) 62%);
    background-size: 400% 100%;
    background-position: -167% 0%;
    svg path {
      transition: all 0.4s;
    }
    &:hover {
      background-position: -265% 0%;
      color: $blue;
      svg path {
        fill: $blue;
      }
    }
  }
}

a.link {
  color: $primary;
  display: flex;
  font-size: 14px;
  text-transform: uppercase;
  align-items: center;
  transition: all 0.3s;
  svg {
    margin-left: 12px;
    path {
      transition: all 0.3s;
    }
  }

  // &:hover {
  //   color: $dark-primary;
  //   path {
  //     fill: $dark-primary;
  //   }
  // }
}


// Titre h2 - section
.title_top {
  padding-right: 40%;
  // padding-bottom: $pad-large;
  @include break(tablet){
    padding-right: 0%;
    // padding-bottom: $pad-large_mobile;
  }

  .txt {
    padding-top: $pad-txt;
    @include break(tablet){
      padding-bottom: 5px;
    }
  }
}

.title_top-2col {
  display: flex;
  justify-content: space-between;
  @include break(tablet){
    padding-right: 0%;
    flex-direction: column;
  }

  .content {
    .title-h2 {
      color: $primary;
    }
    width: 50%;
    flex-shrink: 1;
    @include break(tablet){
      width: 100%;
      margin-bottom: $pad-xsmall;
    }
  }

  .txt {
    padding-top: $pad-txt;
    @include break(tablet){
      padding-bottom: 5px;
    }
  }


}


// Padding
.sec-padding-top_grand {
  @include padding-top-section_big;
}
.sec-padding-bottom_grand {
  @include padding-bottom-section_big;
}
.sec-padding-top_small {
  @include padding-top-section_small;
}
.sec-padding-bottom_small {
  @include padding-bottom-section_small;
}


// POPUP
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 999999;
  align-items: center;
  justify-content: center;

  .inner {
    position: absolute;
    width: 80%;
    height: 80vh;
    transform-origin: center;
    display: flex;
    align-items: center;
    @include absolute-center(center);
    z-index: 2;
    pointer-events: none;
    @include break(mobile){
      width: 100%;
      height: 100vh;
    }
  }

  .video_wrap {
    pointer-events: all;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe, video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 2;
    display: flex;
    align-items: center;
    transition: all 0.4s;
    span {
      font-style: italic;
    }

    svg path {
      transition: all 0.4s;
    }

    &:hover {
      color: $primary;
      svg path {
        fill: $primary;
        stroke: $primary;
      }
    }
  }

  .bg {
    background: rgba($dark, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

.grecaptcha-badge {
    visibility: hidden !important;
}
