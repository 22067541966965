// Module - 04 – Chiffres clés

.module-module-04-key {

    .key_container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include break(mobile) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
        }

        .key {
            width: 28%;
            max-width: 350px;
            position: relative;
            padding-top: 33px;
            padding-bottom: $pad-xsmall;
            @include break(mobile) {
                width: 100%;
                max-width: 100%;
            }

            .line {
                display: block;
                background: $gradient;
                height: 1.5px;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                background-size: 250% 100%;
                background-position: 0% 0%;
                transition: all 0.8s;
            }

            .key_title {
                color: $primary;
                padding-bottom: 10px;
            }

            &:hover {
                .line {
                    background-position: 100% 0%;
                }
            }
        }
    }
    
}