// Module - 08 - Résultats clés

.module-08-results {

    .title_top {
        padding-right: 40%;
        padding-bottom: $pad-large-mobile;
        color: $blue;
        @include break(mobile) {
            padding-right: 0%;
            padding-bottom: $pad-med-mobile;
        }
    }

    .results_container {
        padding-left: 35%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include break(mobile) {
            padding-left: 0%;
        }

        .result {
            width: 46%;
            max-width: 350px;
            position: relative;
            padding-top: 33px;
            padding-bottom: $pad-xsmall;
            @include break(mobile) {
                width: 100%;
                max-width: 100%;
            }

            .line {
                display: block;
                background: $gradient;
                height: 1.5px;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                background-size: 250% 100%;
                background-position: 0% 0%;
                transition: all 0.8s;
            }

            .result_title {
                color: $primary;
                padding-bottom: 10px;
            }

            &:hover {
                .line {
                    background-position: 100% 0%;
                }
            }
        }
    }
}