
// Module - 01 - Header

.single-realisations {
  .module-01-header {
    margin-bottom: 40px!important;

    @include break(mobile) {
      margin-bottom: 10px!important;
    }
  }
}

.module-01-header {
    margin-bottom: $pad-large;
    @include break(mobile) {
        margin-bottom: $pad-large_mobile;
    }


    &.type_home {
        height: 100vh;
        min-height: 870px;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include break(mobile) {
            height: auto;
            min-height: 100vh;
        }

        .container {
            position: relative;
            padding-right: 20%;
            z-index: 5;
            color: $white;
            @include break(mobile) {
                padding-right: 0%;
            }

            h4 {
                padding-top: $pad-txt;
                @include font-light;
            }
            .txt {
                font-size: 1.2em;
                padding-top: $pad-txt;
                max-width: 650px;
                @include break(mobile) {
                    max-width: 100%;
                }
            }
        }

        .filter {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 4;
            background: linear-gradient(0deg, rgba(#343436,1)0%, rgba(#343436,0) 75%);
        }

        video, img {
            @include bg-img;
        }

        .btn_popup {
            opacity: 0;
            position: absolute;
            // right: 5%;
            // bottom: 60px;
            z-index: 5;
            cursor: pointer;
            transition: opacity 0.4s, transform 0.4s;
            @include break(tablet) {
                opacity: 1;
                right: 10%!important;
                bottom: 60px!important;
            }
            @include break(mobile) {
                right: 10%;
                bottom: 60px;
            }

            .txt {
                position: absolute;
                top: 50%;
                left: 66%;
                transform: translate(-50%, -50%) rotate(180deg);
                transform-origin: 38% 47%;
                animation: rotate 4s infinite $linear;

                &_en {
                  top: 50%;
                  left: 88%;
                  transform-origin: 6% 47%;
                }
            }

            &:hover {
                transform: scale(1.4);
            }
        }
    }


    &.type_col {
        height: 100vh;
        min-height: 870px;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: $gradient;
        color: $white;
        @include break(mobile) {
            height: auto;
            min-height: 0;
            padding-bottom: 0;
            padding-top: $pad-xlarge;
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include break(mobile) {
                flex-direction: column;
                width: 100%;
            }

            .img {
                flex-shrink: 0;
                width: 42.5%;
                padding-bottom: 45.5%;
                @include container-img;
                @include break(mobile) {
                    width: 100%;
                    border-radius: 0;
                    padding-bottom: 100%;
                    margin-top: $pad-med-mobile;
                }
            }

            .content {
                padding-right: 7.5%;
                &.pad-r-small {
                    padding-right: 1.5%;

                    h1 {
                      font-size: 3.6em;
                      @include break(desktop) {
                        font-size: 3em;
                      }
                      @include break(tablet){
                        font-size: 2.5em;
                      }
                    }
                }
                @include break(mobile) {
                    padding-right: 5%;
                    padding-left: 5%;
                }
                h4 {
                    padding-top: $pad-txt;
                    @include font-light;
                }
                .txt {
                    font-size: 1.2em;
                    padding-top: $pad-txt;
                    @include font-light;
                }
            }
        }
    }

    &.type_article {
        padding-top: $pad-xlarge;

        .back_container {
            padding-bottom: $pad-xsmall;

            .back {
                display: flex;
                align-items: center;
                @include font-medium;
                color: $blue;
                transition: all 0.4s;

                span {
                    margin-left: 15px;
                }

                &:hover {
                    color: $secondary;
                }
            }
        }

        .content {
            max-width: 930px;
            color: $primary;
            text-align: center;
            padding-bottom: $pad-med;
            @include break(mobile) {
               padding-bottom: $pad-med-mobile;
            }
            h4 {
                padding-top: $pad-txt;
                @include font-light;
            }
            .txt {
                font-size: 1.2em;
                padding-top: $pad-txt;
            }

            .cat_wrap {
                padding-top: $pad-xxxsmall;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                .cat {
                    display: inline-flex;
                    padding: 9px 18px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 30px;
                    border: 1.5px solid $blue;
                    color: $blue;
                    @include font-medium;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }

        .img {
            width: 100%;
            position: relative;
            height: 48.55vw;
            max-height: 1000px;
            box-sizing: border-box;
            max-width: 2048px;
            margin: auto;
            @include break(mobile) {
                height: 60vw;
            }

            img {
                @include bg-img;
            }

            .box_key {
                position: absolute;
                background-color: $blue;
                border-radius: $border-rad;
                width: 250px;
                height: 215px;
                padding: 20px;
                right: 5%;
                bottom: 0;
                transform: translateY(50%);
                color: $white;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @include break(mobile) {
                    padding: 12px;
                    width: 180px;
                    height: 130px;

                    .title-h2 {
                        font-size: 30px;
                    }
                }
                .text {
                    padding-top: 10px;
                    font-size: 0.875em;
                    @include break(mobile) {
                        padding-top: 5px;
                        font-size: 14px;
                        line-height: 120%;
                    }
                }
            }
        }
    }

    &:hover {
        .btn_popup {
            opacity: 1;
        }
    }
}
