// Module - 09 – CTA mosaiques images

.module-09-mosaique {

    padding-top: 400px;
    padding-bottom: 400px;
    position: relative;
    @include break(tablet) {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .content {
        position: relative;
        z-index: 3;
        max-width: 650px;
        text-align: center;

        .title-h2 {
            color: $blue;
        }

        .txt {
            padding-top: $pad-xxxsmall;
        }

        .btn {
            margin-top: $pad-xsmall;
        }
    }

    .img {
        background: $gradient;
        @include container-img;
        position: absolute;
        z-index: 1;
        width: 260px;
        height: 260px;
        @include break(small-screen) {
            width: 200px;
            height: 200px;
        }
        @include break(tablet) {
            width: 108px;
            height: 108px;
        }

        &_1 {
            top: 60px;
            right: 25%;
            @include break(tablet) {
                top: 20px;
                right: 35%;
            }
        }

        &_2 {
            top: 130px;
            left: 25%;
            width: 180px;
            height: 180px;
            @include break(small-screen) {
                width: 140px;
                height: 140px;
            }
            @include break(tablet) {
                width: 75px;
                height: 75px;
                left: -20px;
                top: 100px;
            }
        }

        &_3 {
            top: 190px;
            right: -50px;
            width: 180px;
            height: 180px;
            @include break(small-screen) {
                width: 140px;
                height: 140px;
            }
            @include break(tablet) {
                width: 75px;
                height: 75px;
                right: -10px;
                top: 100px;
            }
        }

        &_4 {
            top: 330px;
            left: -85px;
            @include break(small-screen) {
                top: 160px;
            }
            @include break(tablet) {
                display: none;
            }
        }

        &_5 {
            bottom: 300px;
            right: 4%;
            @include break(tablet) {
                right: -40px;
                bottom: 120px;
            }
        }

        &_6 {
            bottom: 240px;
            left: 8%;
            width: 180px;
            height: 180px;
            @include break(small-screen) {
                width: 140px;
                height: 140px;
                bottom: 320px;
                left: 2.5%;
            }
            @include break(tablet) {
                width: 75px;
                height: 75px;
                left: -40px;
                bottom: 160px;
            }
        }

        &_7 {
            bottom: 60px;
            left: 30%;
            @include break(small-screen) {
                left: 15%;
            }
            @include break(tablet) {
                left: 15%;
                bottom: 40px;
            }
        }

        &_8 {
            bottom: 60px;
            right: 30%;
            width: 180px;
            height: 180px;
            @include break(small-screen) {
                width: 140px;
                height: 140px;
                right: 20%;
                bottom: 70px;
            }
            @include break(tablet) {
                width: 75px;
                height: 75px;
                right: 15%;
                bottom: 20px;
            }
        }
    }
}