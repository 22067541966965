// Module 15 – Bannière image ou vidéo

.module-15-banner .banner_container {
    position: relative;
    height: 640px;
    width: 100%;
    @include break(mobile) {
        height: auto;
        width: 100%;
        padding-bottom: 65%;
    }

    &.container {
        overflow: hidden;
        border-radius: 12px;
        @include break(mobile) {
            width: 90%;
        }
    }

    .img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .icon {
            @include absolute-center(center);
            z-index: 99;
            cursor: pointer;
            transition: all 0.4s;
            
        }

        &:hover .icon{
            transform: translate(-50%,-50%) scale(1.1);
        }

        img {
            @include bg-img;
        }
    }
}