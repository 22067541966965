header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  opacity: 0;
  pointer-events: none;

  .bg {
    display: none;
    background-color: $white;
    position: absolute;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    top: 30px;
    right: 4%;
    opacity: 0;

    @include break(tablet) {
      display: block;
    }
  }

  .container {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding-top: 40px;
      padding-bottom: 40px;
      pointer-events: auto;
    @include break(small-screen) {
      padding-top: 22px;
      padding-bottom: 22px;
    }
  }

  .logo_container {
    position: relative;
    .logo {
      width: 165px;
      height: auto;
      transition: all 0.4s;
      @include break(tablet) {
        width: 120px;
      }

      path {
        transition: all 0.4s;
      }
    }
  }

  .nav {
    .menu_wrap {
      display: flex;
      margin-top: -30px;
      @include break(tablet) {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 100%;
        height: 100vh;
        display: none;
        margin-top: 0;
      }
      .menu-nav-container {
        @include break(tablet) {
          width: 100%;
        }
      }
      .menu {
        display: flex;
        @include break(tablet) {
          width: 100%;
          flex-direction: column;
          border-top: 1px solid #E9EDF2;
          padding: 20px 5%;
        }
      }
      li {
        margin-left: 30px;
        line-height: 100%;
        @include break(tablet) {
          margin-left: 0;
          border-bottom: 1px solid #E9EDF2;
          &:last-of-type {
            border-bottom: none;
          }
        }

        &.wpml-ls-item {
          border-left: solid 1.5px $blue;
          padding-left: 30px;
          transition: all 0.4s;
          text-transform: uppercase;
          @include break(tablet) {
            padding-left: 0px;
            border-left: none!important;
          }
        }
      }
      a {
        @include font-medium;
        color: $blue;
        transition: all 0.4s;
        @include break(tablet) {
          color: $dark!important;
          padding-top: 12px;
          padding-bottom: 12px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 24px;
          @include font-medium;
          line-height: 150%;

          &::after {
            content: " ";
            background-image: url('../img/icon_arrow.svg');
            width: 8px;
            height: 14px;
            background-position: center;
            background-size: cover;
          }
        }
        &:hover {
          color: $secondary;
        }
      }
    }
  }

  .nav_mobile {
    display: none;
    position: relative;
    cursor: pointer;
    color: $blue;
    margin-top: -20px;
    @include break(tablet) {
      display: flex;
    }
    .burger_txt {
      padding-right: 14px;
      transition: all 0.4s;
      margin-top: -2px;
    }
    .burger {
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      div {
        background-color: $blue;
        width: 100%;
        height: 2px;
        transition: all 0.4s;
      }
    }
    .close {
      opacity: 0;
      position: absolute;
      top: -10px;
      left: 7px;
      font-size: 35px;
      line-height: 41px;
    }
  }

  &.secondary {
    .logo .logo_path {
      fill: $cyan;
    }

    .nav .menu a {
      color: $white;
    }

    .nav .menu li.wpml-ls-item {
      border-left: solid 1.5px $white;
    }

    .nav_mobile {
      color: $white;
    }
    .nav_mobile .burger div {
      background-color: $white;
    }
  }

  &.home {
    .nav .menu a {
      color: $white;
    }
    .nav .menu li.wpml-ls-item {
      border-left: solid 1.5px $white;
    }

    .nav_mobile {
      color: $white;
    }
    .nav_mobile .burger div {
      background-color: $white;
    }
  }



// Custom colors
  &[class*="color-logo-blue"] {
    .logo .logo_path {
      fill: $blue;
    }
  }
  &[class*="color-logo-teal"] {
    .logo .logo_path {
      fill: $cyan;
    }
  }
  &[class*="color-logo-white"] {
    .logo .logo_path {
      fill: $white;
    }
  }

  &[class*="color-tag-grey"] {
    .logo .tag_path {
      fill: $dark;
    }
  }
  &[class*="color-tag-white"] {
    .logo .tag_path {
      fill: $white;
    }
  }

  &[class*="color-menu-blue"] {
    .nav .menu a {
      color: $blue;
    }
    .nav .menu li.wpml-ls-item {
      border-left: solid 1.5px $blue;
    }

    .nav_mobile {
      color: $blue;
    }
    .nav_mobile .burger div {
      background-color: $blue;
    }
  }

  &[class*="color-menu-white"] {
    .nav .menu a {
      color: $white;
    }
    .nav .menu li.wpml-ls-item {
      border-left: solid 1.5px $white;
    }

    .nav_mobile {
      color: $white;
    }
    .nav_mobile .burger div {
      background-color: $white;
    }
  }
  











  &.open {
    .logo .logo_path {
      fill: $blue!important;
    }
    .logo .tag_path {
      fill: $dark!important;;
    }

    .nav_mobile {
      color: $blue;

      .burger_txt {
        opacity: 0;
        transform: translateX(-20px);
      }
    }
    .nav_mobile .burger div {
      background-color: $blue;
      &:nth-of-type(1) {
        transform: rotate(45deg) translate(6px, 6px);
      }
      &:nth-of-type(2) {
        width: 0;
      }
      &:nth-of-type(3) {
        transform: rotate(-45deg) translate(5px, -5px);
      }
     }
  }
}
