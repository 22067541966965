/*  --- Variable ---  */
$font-size: 20px;
// $header-h: 120px;
// $header-h_mobile: 112px;
$container: 1280px;
$border-rad: 12px;

/*  --- Padding ---  */
$pad-txt: 20px;
$pad-xxxsmall: 28px;
$pad-xxsmall: 32px;
$pad-xsmall: 40px;
$pad-small: 68px;
$pad-med: 80px;
$pad-large: 100px;
$pad-xlarge: 162px;

// $pad-med_mobile: 32px;
$pad-med-mobile: 40px;
$pad-large-mobile: 60px;
$pad-xlarge_mobile: 80px;
$pad-xxlarge_mobile: 80px;

//COLOR
$dark: #424653;
$white: #FFFFFF;
$blue: #0070FA;
$cyan: #00E6E6;

$primary: #0070FA;
$secondary: #00E6E6;

$gradient: linear-gradient(250deg, $cyan -4.71%, $blue 59.67%);
