// Module - 12 - Plans // 2 colonnes

.module-12-plans-2col {

    .plan_big {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: $pad-xlarge;
        @include break(mobile){
            flex-direction: column-reverse;
            padding-bottom: $pad-xlarge_mobile;
        }
        &:last-of-type {
            padding-bottom: 0;
        }
        &:nth-of-type(even) {
            flex-direction: row-reverse;
            @include break(mobile){
                flex-direction: column-reverse;
            }
            .link_real {
                border-radius: 12px 0px 0px 12px!important;
                @include break(mobile){
                    border-radius: 0!important;
                }
            }
            .content {
                padding-left: calc((100vw - 1280px) / 2)!important;
                padding-right: 7.5%!important;
                @include break(small-screen){
                    padding-left: 5%!important;
                    padding-right: 7.5%;
                }
            }
        }

        .link_real {
            width: 50%;
            position: relative;
            padding-bottom: 45.5%;
            flex-shrink: 0;
            border-radius: 0px 12px 12px 0px;
            overflow: hidden;
            @include break(mobile){
                width: 100%;
                padding-bottom: 85.5%;
                border-radius: 0!important;
            }

            .card-plan_overlay-bg {
                position: absolute;
                width: 62px;
                height: 62px;
                background-color: $blue;
                border-radius: 50%;
                top: 32px;
                right: 32px;
                transition: all 0.6s;
                z-index: 1;
                @include break(mobile){
                    top: 22px;
                    right: 22px;
                    width: 52px;
                    height: 52px;
                }
            }

            .card-plan_top {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: $gradient;

                img {
                    @include bg-img;
                }

                .arrow {
                    position: absolute;
                    top: 32px;
                    right: 32px;
                    width: 62px;
                    height: 62px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 4;
                    transition: all 0.4s;
                    background-color: rgba($secondary, 0);
                    border-radius: 50%;
                    svg {
                     width: 36px;
                     height: 36px;
                     path {
                         transition: fill 0.4s;
                     }
                    }

                    @include break(mobile){
                         top: 22px;
                         right: 22px;
                         width: 52px;
                         height: 52px;
                         svg {
                             width: 28px;
                             height: 28px;
                         }
                     }
                 }
            }

            .card-plan_overlay {
                position: absolute;
                top: 40px;
                left: 35px;
                right: 35px;
                bottom: 40px;
                color: $white;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                overflow: hidden;
                z-index: 4;
                @include break(mobile){
                    top: 20px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                }

                .card-plan_txt-inner {
                    transform: translateY(100%);
                    opacity: 0;
                    transition: all 0.6s 0.1s;
                    @include break(mobile){
                        transform: translateY(0%);
                        opacity: 1;
                        padding: 20px 5%;
                        background: linear-gradient(180deg, rgba(52, 52, 54, 0.00) 0%, #343436 100%);
                    }
                }

                h6 {
                    padding-bottom: 8px;
                    font-weight: 500;
                    @include break(mobile){
                        padding-bottom: 6px;
                    }
                }
                h3 {
                    padding-bottom: 16px;
                    @include break(mobile){
                        padding-bottom: 8px;
                    }
                }
                .txt {
                    @include break(mobile){
                        display: none;
                    }
                }
            }

            &:hover {
                .arrow {
                    background-color: rgba($secondary, 1);
                    transform: scale(1.1);
                    svg path {
                        fill: $blue;
                    }
                }

                .card-plan_overlay-bg {
                    transform: scale(100);
                }
                .card-plan_txt-inner {
                    transform: translateY(0%);
                    opacity: 1;
                }
            }
        }

        .content {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-right: calc((100vw - 1280px) / 2);
            padding-left: 7.5%;
            @include break(small-screen){
                padding-right: 5%;
                padding-left: 7.5%;
            }
            @include break(mobile){
                width: 100%;
                padding-left: 5%;
                padding-bottom: $pad-med-mobile;
            }

            .title-h2 {
                color: $blue;
                padding-bottom: $pad-xsmall;
                @include break(mobile){
                    padding-bottom: 12px;
                }
            }

            .txt-short {
                padding-bottom: 12px;
                @include break(mobile){
                    padding-bottom: 6px;
                }
            }
            .txt-objectif {
                padding-top: 12px;
                @include break(mobile){
                    padding-bottom: 6px;
                }
            }

            .btn {
                margin-top: 50px;
                @include break(mobile){
                    margin-top: 18px;
                }
            }
        }
    }
}
