//FONT
@mixin font-light{
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}
@mixin font-regular{
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin font-medium{
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}
@mixin font-bold{
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}
@mixin font-title{
  font-family: "Noto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}