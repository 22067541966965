// Module - 16 – 2 Images


.module-16-2images {

    .container {
        display: flex;
        justify-content: space-between;

        .img {
            width: 47.5%;
            @include container-img;
            padding-bottom: 47.5%;

            @include break(mobile) {
                width: 49%;
                padding-bottom: 49%;
            }
        }
    }

}