.template-contact {
    padding-bottom: 200px;
    @include break(tablet) {
        padding-bottom: 60px;
     }

    .header-contact {
        .container {
            padding-top: 175px;
            padding-bottom: 60px;
            border-bottom: 1px solid $blue;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include break(tablet) {
               flex-direction: column;
               padding-top: 110px;
            }
            .content {
                width: 50%;
                @include break(tablet) {
                    width: 100%;
                }

                h1 {
                    color: $blue;
                }
                .txt {
                    padding-top: $pad-xxsmall;
                }
            }

            .img_container {
                &--lottie {
                  padding-bottom: 0;
                  @include break(tablet) {
                      width: 100%;
                      margin-top: 40px;
                      padding-bottom: 0;
                  }
                }
                width: 37%;
                @include container-img;
                border-radius: 0;
                padding-bottom: 29.5%;
                @include break(tablet) {
                    width: 100%;
                    margin-top: 40px;
                    padding-bottom: 65%;
                }

                #lottie-animation {
                    width: 100%;
                    height: 100%;
                }

                .btn_popup {
                    position: absolute;
                    right: 6%;
                    bottom: 24px;
                    z-index: 5;
                    cursor: pointer;
                    transition: all 0.4s;
                    @include break(mobile) {
                        right: 10%;
                    }

                    .txt {
                        position: absolute;
                        top: 50%;
                        left: 66%;
                        transform: translate(-50%, -50%) rotate(180deg);
                        transform-origin: 38% 47%;
                        animation: rotate 4s infinite $linear;
                    }

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }

    .sec-form .container {
        padding-top: 60px;
        display: flex;
        justify-content: space-between;
        @include break(tablet) {
            flex-direction: column;
            padding-top: 40px;
         }

        .content_info {
            width: 35%;
            max-width: 335px;
            @include break(tablet) {
                width: 100%;
            }

            .contact {
                padding-bottom: 40px;
                @include break(tablet) {
                    padding-bottom: 32px;
                }

                .contact_title {
                    text-transform: uppercase;
                    color: $blue;
                    font-size: 16px;
                    @include font-medium;
                    padding-bottom: 16px;
                    @include break(tablet) {
                        padding-bottom: 8px;
                    }
                }
            }
        }

        .content_form {
            width: 55%;
            height: auto;
            min-height: 500px;
            @include break(tablet) {
                width: 100%;
                padding-top: 30px;
            }
        }
    }
}
