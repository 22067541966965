// Module - 18 – Articles

.module-18-articles {

    .card_container {
        display: flex;
        justify-content: space-between;
        overflow-y: hidden;
        overflow-x: scroll;
        &::-webkit-scrollbar { width: 0 !important };
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
        min-height: -webkit-fill-available;
        padding-left: calc((100vw - 1280px) / 2);
        padding-right: calc((100vw - 1280px) / 2);
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        @include break(small-screen){
            padding-left: 5%;
        }


        .card_article {
            width: 45%;
            margin-right: 2%;
            flex-shrink: 0;
            cursor: pointer;
            margin-bottom: $pad-med-mobile;
            scroll-snap-align: start;
            scroll-margin: calc((100vw - 1280px) / 2);
            @include break(small-screen){
                scroll-margin: 5vw;
            }
            @include break(mobile) {
                width: 90%;
                margin-right: 5%;
            }

            .img {
                width: 100%;
                @include container-img;
                padding-bottom: 90%;
                background: $gradient;
                transition: all 0.6s;
                background-size: 100%;

                img {
                    transition: all 0.6s;
                }
            }

            .content {
                padding-top: $pad-txt;
                @include break(mobile) {
                    padding-top: 18px;
                }
                h6 {
                    color: $blue;
                    font-weight: 600;
                    font-size: 1.4em;
                    transition: all 0.6s;
                }
                .cat_wrap {
                    display: flex;
                    flex-wrap: wrap;
                    @include font-medium;
                    padding-top: 18px;
                    @include break(mobile) {
                        padding-top: 14px;
                    }
                    .cat {
                        font-size: 12px;
                        border-radius: 20px;
                        border: 1px solid #424653;
                        padding: 6px 12px;
                        text-align: center;
                        line-height: 100%;
                        margin-right: 12px;
                    }
                }
            }

            &:hover {
                .img {
                    background-size: 165%;
                }
                h6 {
                    color: $secondary;
                }
                img {
                    transform: translate(-50%,-50%) scale(1.025);
                }
            }
        }
    }
}
