footer {
  width: 100%;
  overflow: hidden;
  background: $gradient;
  color: $white;
  padding-top: $pad-med;
  padding-bottom: $pad-xxsmall;
  @include break(tablet) {
    padding-top: $pad-large-mobile;
  }

  .logo_container {
    display: flex;
    align-items: center;

    @include break(tablet) {
      display: block;
    }

    .col1 {
      width: 200px;
      flex-shrink: 0;
      padding-right: 30px;
      @include break(tablet) {
        width: 100%;
        margin-bottom: 30px;
      }
      @include break(mobile) {
        width: 100%;
        margin-bottom: 10px;
      }
      svg {
        width: auto;
        width: 110px;
        @include break(tablet) {
          width: 90px;
        }
      }
    }

    .col2 {
      flex-shrink: 0;
      width: 200px;

      img, svg {
        height: 62px;
        width: auto;
        margin-top: 20px;
        @include break(tablet) {
          margin-top: 0px;
          margin-bottom: 20px;
        }
      }
    }

    .col3 {
      display: flex;
      justify-content: flex-start;
      padding-left: 7.5%;
      width: 100%;
      @include break(tablet) {
        padding-left: 0;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
      }
      @include break(mobile) {
        display: block;
        margin-bottom: 20px;
      }
      img, svg {
        width: auto;
        height: 60px;
      }

      &__logos {

        &--description {
          font-size: 14px;
          line-height: 20px;
          padding-bottom: 16px;
          @include break(mobile) {
            padding-top: 16px;
            padding-bottom: 0px;
          }
        }
        &--logo1 {
          svg {
            max-width: 214px;
            @include break(mobile) {
              display: block;
              padding-top: 20px;
            }
          }
        }

        &--logo2 {
          padding-left: 40px;
          @include break(mobile) {
            display: block;
            padding-left: 0;
            padding-top: 20px;
          }
        }
      }

    }
  }

  .footer_container {
    display: flex;
    padding-top: $pad-small;
    @include break(tablet) {
      padding-top: $pad-xxsmall;
      flex-wrap: wrap;
    }

    a {
      transition: all 0.4s;

      &:hover {
        color: $secondary;
      }
    }
    .col1 {
      width: 200px;
      flex-shrink: 0;
      padding-right: 30px;
      @include break(tablet) {
        width: 50%;
        padding-right: 2.5%;
        order: 2;
      }
      li {
        padding-top: 12px;
      }
    }
    .col2 {
      flex-shrink: 0;
      width: 200px;
      @include break(tablet) {
        width: 50%;
        padding-left: 2.5%;
        order: 3;
      }

      a {
        display: block;
        padding-top: 12px;
        font-size: 16px;
      }

      .social_wrap {
        display: flex;
        flex-wrap: wrap;
        a {
          margin-right: 20px;
          margin-bottom: 20px;
          svg {
            width: 40px!important;

            path {
              transition: all 0.4s;
            }
          }

          &:last-of-type {
            margin-right: 0;
          }

          &:hover svg path{
            fill: $secondary;
          }
        }
      }
    }
    .newsletter {
      padding-left: 7.5%;
      width: 100%;
      flex-shrink: 1;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: flex-end;
      order: 1;
      @include break(tablet) {
        padding-left: 0;
        margin-bottom: 50px;
      }

      .txt {
        font-size: 1.4em;
        line-height: 130%;
        @include font-title;
        padding-bottom: 60px;
        @include break(tablet) {
          font-size: 22px;
          padding-bottom: $pad-xxsmall;
        }
      }

      form {
        .form_wrap {
          width: 100%;
          display: flex;
          @include break(tablet) {
            flex-direction: column;
          }

          p {
            &:first-of-type {
              width: 100%;
              flex-shrink: 1;
              margin-right: 60px;
            }
            &:last-of-type {
              width: auto;
              flex-shrink: 0;
              margin-right: 0px;
            }
          }
        }
        input {
          outline: none;
          border: none;
          border-bottom: 1px solid $white;
          background-color: transparent;
          color: $white;
          padding: 12px 10px;
          width: 100%;
          font-size: 16px;
          @include break(tablet) {
            margin-right: 0px;
          }


          &[type=submit] {
            padding: 16px 24px;
            border-radius: 30px;
            border: 1px solid $white;
            margin-right: 0px;
            transition: all 0.3s;
            cursor: pointer;
            @include break(tablet) {
              margin-top: $pad-xsmall;
            }

            &:hover {
              background-color: $white;
              color: $blue;
            }
          }
        }

        ::placeholder {
          color: rgba(255,255,255,0.5);
        }

        .wpcf7-spinner {
          display: none;
        }

        .wpcf7-not-valid-tip {
          font-size: 14px;
          margin-top: 5px;
        }
      }
      .wpcf7 form .wpcf7-response-output{
        border: none;
        padding: 0;
        margin: 0;
        margin-top: 20px;
        font-size: 16px;
      }
    }
  }

  .footer_bottom {

    &--primary {
      padding-top: $pad-med;
      font-size: 14px;
      @include break(tablet) {
        padding-top: $pad-med-mobile;
      }
    }

    &--secondary {
      margin-top: 10px;
      padding-top: 10px;
      font-size: 13px;
      line-height: 20px;
      border-top: 1px solid #5bb5ff;
    }

  }
}
