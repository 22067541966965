// ARCHIVES RÉALISATIONS ET ARTICLES

.template-archive {
    padding-bottom: 0;

    .header_archive {
        padding-top: 180px;
        padding-bottom: 140px;
        @include break(mobile) {
            padding-top: $pad-xxlarge_mobile;
            padding-bottom: $pad-large-mobile;
        }
        .content {
            padding-right: 50%;
            @include break(tablet) {
                padding-right: 0%;
            }
            h1 {
                color: $blue;
                padding-bottom: 28px;
            }
        }
    }

    .filter_container {
        display: flex;
        justify-content: flex-start;
        overflow-x: scroll;
        overflow-y: hidden;
        padding-bottom: $pad-med;
        z-index: 9999;
        position: relative;
        &::-webkit-scrollbar { width: 0 !important };
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
        @include break(mobile) {
            padding-bottom: $pad-med-mobile;
        }

        .filter {
            border-radius: 30px;
            border: 1px solid $dark;
            display: flex;
            padding: 10px 20px;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            @include font-medium;
            line-height: 140%;
            margin-right: 20px;
            transition: all 0.4s;
            cursor: pointer;
            flex-shrink: 0;

            &:hover, &.active {
                border-color: $blue;
                color: $blue;
            }
        }
    }

    .post_container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: $pad-large;
        @include break(mobile) {
            padding-bottom: $pad-large-mobile;
        }

        .card_article-big {
            width: 47.5%;
            cursor: pointer;
            transition: none;
            @include break(mobile) {
                width: 100%;
                margin-bottom: $pad-med-mobile;
            }

            .img {
                width: 100%;
                @include container-img;
                padding-bottom: 90%;
                background: $gradient;
                transition: all 0.6s;
                background-size: 100%;

                img {
                    transition: all 0.6s;
                }
            }

            .content {
                padding-top: $pad-txt;
                @include break(mobile) {
                    padding-top: 18px;
                }
                h6 {
                    color: $blue;
                    font-weight: 600;
                    font-size: 1.4em;
                    transition: all 0.6s;
                }
                .cat_wrap {
                    display: flex;
                    flex-wrap: wrap;
                    @include font-medium;
                    padding-top: 18px;
                    @include break(mobile) {
                        padding-top: 14px;
                    }
                    .cat {
                        font-size: 12px;
                        border-radius: 20px;
                        border: 1px solid #424653;
                        padding: 6px 12px;
                        text-align: center;
                        line-height: 100%;
                        margin-right: 12px;
                    }
                }
            }

            &:hover {
                .img {
                    background-size: 165%;
                }
                h6 {
                    color: $secondary;
                }
                img {
                    transform: translate(-50%,-50%) scale(1.025);
                }
            }

            &:nth-child(even) {
                margin-top: 10%;
                @include break(mobile) {
                    margin-top: 0;
                }
            }

            &.hidden {
                display: none;
            }
        }
    }

    .no_result {
        display: none;
        margin-top: -120px;
        margin-bottom: 80px;
        @include break(mobile) {
            margin-top: -40px;
            margin-bottom: 60px;
        }
    }

    .btn_container {
        display: flex;
        justify-content: center;
        padding-bottom: 70px;
        .disabled {
            opacity: 0;
            pointer-events: none;
        }
    }

    .module-13-cta {
        padding-top: 0;
    }
}
