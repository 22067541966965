// Module - 17 – Réalisations

.module-17-realisations {

    .card_container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .card_article {
            width: 47.5%;
            cursor: pointer;
            margin-bottom: $pad-med-mobile;
            @include break(mobile) {
                width: 100%;
            }

            .img {
                width: 100%;
                @include container-img;
                padding-bottom: 90%;
                // background: $gradient;
                transition: all 0.6s;
                background-size: 100%;

                img {
                    transition: all 0.6s;
                }
            }

            .content {
                padding-top: $pad-txt;
                @include break(mobile) {
                    padding-top: 18px;
                }
                h6 {
                    color: $blue;
                    font-weight: 600;
                    font-size: 1.4em;
                    transition: all 0.6s;
                }
                .cat_wrap {
                    display: flex;
                    flex-wrap: wrap;
                    @include font-medium;
                    padding-top: 18px;
                    @include break(mobile) {
                        padding-top: 14px;
                    }
                    .cat {
                        font-size: 12px;
                        border-radius: 20px;
                        border: 1px solid #424653;
                        padding: 6px 12px;
                        text-align: center;
                        line-height: 100%;
                        margin-right: 12px;
                    }
                }
            }

            &:hover {
                .img {
                    background-size: 165%;
                }
                h6 {
                    color: $secondary;
                }
                img {
                    transform: translate(-50%,-50%) scale(1.025);
                }
            }
        }
    }
}
