// Module - 07 - Contenu Texte

.module-07-text {

    .title_container {
        padding-right: 40%;
        padding-bottom: $pad-med;
        color: $blue;
        @include break(mobile) {
            padding-right: 0%;
            padding-bottom: $pad-med-mobile;
        }
    }

    .text_container {
        padding-left: 35%;
        @include break(mobile) {
            padding-left: 0%;
        }

        .btn {
            margin-top: $pad-xsmall;
            @include break(mobile) {
                margin-top: $pad-xxxsmall;
            }
        }
    }

}