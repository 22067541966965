@import 'partials/_mixin';
@import 'partials/_function';

@import "./base/variable";
@import "./base/wordpress";
@import './base/breakpoint';
@import "./base/reset.scss";
@import './base/normalize';
@import './base/easing';
@import './base/fonts';
@import './base/layout';

@import './common/header';
@import './common/footer';
@import "./common/general";
@import "./common/anim-class";

@import './pages/template-contact';
@import './pages/template-archive';
@import './pages/quatrecentquatre';

@import './modules/module-01-header';
@import './modules/module-02-logos';
@import './modules/module-03-intro_texte';
@import './modules/module-04-key';
@import './modules/module-05-2col';
@import './modules/module-06-steps';
@import './modules/module-07-text';
@import './modules/module-08-results';
@import './modules/module-09-mosaique';
@import './modules/module-10-quote';
@import './modules/module-11-plans-slider';
@import './modules/module-12-plans-2col';
@import './modules/module-13-cta';
@import './modules/module-14-cta-2col';
@import './modules/module-15-banner';
@import './modules/module-16-2images';
@import './modules/module-17-realisations';
@import './modules/module-18-articles';