// Module - 02 - Logos

.module-02-logos {
    .title_top {
        padding-right: 0;
        text-align: center;
        max-width: 920px;
        color: $blue;
        overflow: hidden;
    }

    .bandeau_container {
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
    }
    .bandeau {
        display: inline-flex;
        width: auto;
        flex-shrink: 0;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
        transform-style: preserve-3d;

        .logo {
            position: relative;
            width: 18vw;
            height: 10vw;
            overflow: hidden;
            @include break(tablet) {
                width: 33vw;
                height: 15vw;
            }
            @include break(mobile) {
                width: 38vw;
                height: 26vw;
            }
            img {
                @include bg-img;
                object-fit: contain;
            }
        }


    }

}
