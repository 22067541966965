// Module - 06 - Méthodologie slider

.module-06-steps {
    overflow: initial;

    .title_top {
        padding-bottom: $pad-med;
    }
    .step_container{
        position: relative;
        display: flex;
        justify-content: space-between;
        position: relative;

        .col_img {
            padding-top: $pad-med;
            width: 40%;
            @include break(tablet) {
                width: 100%;
                padding-top: 0;
            }
            .img {
                @include container-img;
                padding-bottom: 119.5%;
                margin-bottom: $pad-xsmall;
                @include break(tablet) {
                    margin-bottom: $pad-txt ;
                }
            }

            .content_mobile {
                display: none;
                @include break(tablet) {
                    display: block;
                    margin-bottom: $pad-large-mobile;
                    &:last-of-type {
                        padding-bottom: 0;
                    }
                }

                h3 {
                    color: $blue;
                    padding-bottom: $pad-txt;
                }
                .txt {
                    font-size: 1em;
                }
            }
        }

        .col_left {
            position: relative;
            width: 50%;
            flex-shrink: 0;
            @include break(tablet) {
                display: none;
            }

            .content_sticky {
                position: sticky;
                top: 100px;
                display: flex;
                height: calc(100vh - 200px);
                align-items: center;

                .inner {
                    position: relative;
                    height: 600px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding-left: 10%;

                    @include break(desktop) {
                      height: 700px;
                    }
                }
                .line {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    border-radius: 12px;
                    background: rgba(0, 36, 52, 0.10);
                    width: 2px;
                    overflow: hidden;
                    .line_inner {
                        position: absolute;
                        background-color: $blue;
                        top: 0;
                        left: 0;
                        right: 0;
                        border-radius: 12px;
                        width: 2px;
                    }
                }
                .number_container {
                    color: $blue;
                    @include font-medium;
                    font-size: 0.875em;
                    padding-bottom: 12px;
                }
                .content_container {
                    position: relative;
                    left: 0;
                    right: 0;
                }
                .step {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    opacity: 0;
                    display: flex;
                    flex-direction: column;
                    height: 600px;

                    @include break(desktop) {
                      height: 700px;
                    }

                    &:first-of-type {
                        opacity: 1;
                    }

                    h4 {
                        color: $blue;
                        padding-bottom: $pad-xxxsmall;
                        flex-shrink: 0;
                    }
                    .txt_container {
                        height: 100%;
                        flex-shrink: 1;
                        overflow-y: scroll;
                        overflow-x: hidden;
                        font-size: 0.875em;
                        &::-webkit-scrollbar { width: 0 !important };
                        overflow: -moz-scrollbars-none;
                        -ms-overflow-style: none;
                    }
                }
            }
        }
    }
}
