
// Module - 03 - Intro Texte

.module-03-intro_texte {
    padding-bottom: 160px;

    .content {
        color: $blue;
        font-size: 1.7em;
        line-height: 170%;
        @include font-title;
        max-width: 1035px;
        text-align: center;
        @include break(mobile) {
            font-size: 1.3em;
        }

        p {
            @include break(mobile) {
                display: inline;
            }
        }

        .line {
            background: linear-gradient(250deg, rgba(#0070FA, 0.4) 30%, rgba(#00E6E6, 0.7) 50%, rgba(#0070FA, 1) 80%);
            background-size: 400% 100%;
            background-position-x: -167%;
            background-position-y: 0%;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            strong {
            font-weight: 500;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            }

            @include break(mobile) {
                display: inline;
            }
        }
    }

}
