/*
Theme Name: Theme Name
*/




#adminmenu li.wp-menu-separator{
    background-color: currentColor;
 }

