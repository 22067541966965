// Module - 05 - 2 colonnes Img-Txt

.module-05-2col {
    .col_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include break(mobile) {
            flex-direction: column;
            align-items: center;
        }

        .col_img {
            width: 40%;
            @include break(mobile) {
                width: 100%;
            }
            &.img_shape {
                mask-image: url(../img/shape_img.svg);
                mask-repeat: no-repeat;
                mask-position: 50%;
                img {
                    width: 100%;
                }
            }

            &.no-shape {
                @include container-img;
                padding-bottom: 55%;
                @include break(mobile) {
                    padding-bottom: 130%;
                }
            }
        }

        .col_content {
            width: 50%;
            @include break(mobile) {
                width: 100%;
                padding-top: $pad-med-mobile;
            }
            .title-h2 {
                color: $blue;
            }
            .txt {
                padding-top: $pad-xxxsmall;
            }
            .btn {
                margin-top: $pad-xsmall;
            }
        }
    }

    &.layout_img-right {
        .col_container {
            flex-direction: row-reverse;
            @include break(mobile) {
                flex-direction: column;
                align-items: center;
            }
        }
    }
}