.module-13-cta {
    &.flex-block_last {
        padding-bottom: 0;
    }
    .banner_container {
        min-height: 580px;
        padding-top: $pad-large;
        padding-bottom: $pad-large;
        background: $gradient;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include break(mobile) {
            min-height: 0px;
            padding-top: $pad-xlarge_mobile;
            padding-bottom: $pad-xlarge_mobile;
        }

        .filter {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 4;
            background: $dark;
            z-index: 2;
        }

        .bg-img {
            @include bg-img;
            z-index: 1;
        }

        .container {
            position: relative;
            z-index: 3;
        }

        .title_container {
            color: $white;
            text-align: center;
            max-width: 1000px;
            margin-left: auto;
            margin-right: auto;

            .txt {
                padding-top: $pad-xsmall;
                @include break(mobile) {
                    padding-top: $pad-txt;
                }
            }
        }

        .btn_container {
            padding-top: $pad-large-mobile;
            display: flex;
            align-items: center;
            justify-content: center;
            @include break(mobile) {
                flex-direction: column;
                padding-top: 20px;
            }
            .btn {
                margin-left: 20px;
                margin-right: 20px;
                @include break(mobile) {
                    margin-top: 20px;
                    margin-left: 0px;
                    margin-right: 0px;
                }
            }
        }
    }
}
