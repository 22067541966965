// Module - 14 - CTA Bandeau

.module-14-cta-2col {
  &.flex-block_last {
    padding-bottom: 0;
  }
  .banner_container{
    padding-top: $pad-large;
    padding-bottom: $pad-large;
    background: $gradient;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include break(mobile) {
      min-height: 0px;
      padding-top: $pad-xlarge_mobile;
      padding-bottom: $pad-xlarge_mobile;
    }

    .filter {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 4;
      background: $dark;
      z-index: 2;
    }

    .bg-img {
      @include bg-img;
      z-index: 1;
    }

    .container {
      position: relative;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include break(mobile) {
        flex-direction: column;
      }
    }

    .title_container {
      color: $white;
      max-width: 735px;
      width: 100%;
      flex-shrink: 1;
      @include break(mobile) {
        flex-direction: column;
        text-align: center;
      }

      .txt {
        padding-top: $pad-xsmall;
        @include break(mobile) {
          padding-top: $pad-txt;
        }
      }
    }

    .btn_container {
      width: 20%;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: 7.5%;
      min-width: 300px;
      @include break(mobile) {
        min-width: 0px;
        width: 100%;
        padding-top: 40px;
        justify-content: center;
        margin-left: 0%;
      }
    }
  }
}
