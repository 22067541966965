// Module - 10 - Témoignage

.module-10-quote {

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 2.5%;
        padding-left: 2.5%;
        @include break(tablet) {
            flex-direction: column;
            padding-right: 0%;
            padding-left: 0;
        }

        .content {
            width: 52.5%;
            margin-top: -80px;
            @include break(tablet) {
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                margin-top: 0px;
                margin-bottom: 40px;
            }

            .quote {
                width: 80px;
                transform: translateX(-50%);
                padding-bottom: $pad-txt;
                @include break(tablet) {
                    width: 40px;
                }
            }

            .name {
                color: $blue;
                padding-top: $pad-xxsmall;
                @include font-bold;
                font-size: 1.2em;
            }
            .poste {
                color: $blue;
                font-size: 1.1em;
                padding-top: 5px;
            }
        }

        .media {
            width: 40%;
            @include break(tablet) {
                width: 100%;
            }

            .img {
                @include container-img;
                padding-bottom: 100%;

                .icon {
                    @include absolute-center(center);
                    z-index: 99;
                    cursor: pointer;
                    transition: all 0.4s;
                    
                }

                &:hover .icon{
                    transform: translate(-50%,-50%) scale(1.1);
                }
            }
        }
    }

}